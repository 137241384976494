import Site from '@models/site.model';
import * as SiteActions from '@app/actions/site.actions'

export function SiteReducer(state:Site , action:  SiteActions.Actions) {
  switch (action.type) {
    case SiteActions.SiteActionsTypes.ADD_SITE:
      return state = action.payload
    case SiteActions.SiteActionsTypes.CLEAR_SITE:
      return state = undefined

  }

}