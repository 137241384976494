import { Injectable } from '@angular/core';
import * as L from "leaflet"
import Site from '@models/site.model'
import { icon, Marker } from 'leaflet';

@Injectable({
  providedIn: 'root'
})
export class MarkersService {

  public addMarkerToMap(site: Site):Marker {
    return L.marker(
      [site.lat, site.lng],
      {
        rotationAngle: site.orientation,
        rotationOrigin: 'center center',
        icon: icon({
          iconSize: [50, 50],
          iconUrl: site.icon,
          className: 'cone',
          tooltipAnchor: [0, 0]
        })
      })
      .bindTooltip(`${site.id} | ${site.title}`, {
        permanent: false,
        opacity: 1,
        direction: 'top',
        className: 'viewpoint-labels'
      })
  }

}
