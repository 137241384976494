

<div style="height: 100vh;"
     leaflet 
     id="map"
     [leafletOptions]="options"
     [leafletLayers]="layers"
     (leafletMapReady)="onMapLoaded($event);map = $event"
     [leafletLayersControl]="layersControl">
     <map-header (resetView)="resetView()"></map-header>
</div>
