import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router'
import * as OpenSeadragon from 'openseadragon'

import { faSearchPlus, faSearchMinus, faExpandArrowsAlt, faUndoAlt, faCompressArrowsAlt, faTimes } from '@fortawesome/free-solid-svg-icons'

import { LocalStorageService } from 'ngx-localstorage';

// site documents module
import Sites from '@documents/sites.document'

// import model
import Site from '@models/site.model'

type TileSources = string[]

@Component({
  selector: 'app-zoom',
  templateUrl: './zoom.component.html',
  styleUrls: ['./zoom.component.scss']
})
export class ZoomComponent implements OnInit {

  public readonly faSearchPlus = faSearchPlus
  public readonly faSearchMinus = faSearchMinus
  public readonly faExpandArrowsAlt = faExpandArrowsAlt
  public readonly faCompressArrowsAlt = faCompressArrowsAlt
  public readonly faUndoAlt = faUndoAlt
  public readonly faTimes = faTimes
  private zoomFactor: number

  public site: Site

  public expandCompressIcon: any = this.faExpandArrowsAlt
  public viewer: any

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private _storageService: LocalStorageService
  ) {
    this._storageService.asPromisable().get('monitor').then(screen => {
      this.zoomFactor = 1503.93 / screen.actualWidth;
    })

  }

  async ngOnInit() {

    this.site = await this.checkRouteParamForSiteID(history.state.data)
    let tileSources = await this.getTileSources(this.site)

    this.viewer = OpenSeadragon({
      id: "openseadragon",
      prefixUrl: `/assets/viewpoints/${this.site.viewpoints.existing.id}/`,
      tileSources,
      constrainDuringPan: true,
      panHorizontal: true,
      panVertical: true,
      minZoomLevel: 1,
      visibilityRatio: 1.0,
      defaultZoomLevel: this.zoomFactor || 3,
      clickToZoom: true,
      showZoomControl: true,
      showHomeControl: true,
      showFullPageControl: true,
      showRotationControl: false,
      showSequenceControl: false,
      sequenceMode: true,
      preserveViewport: true,
      showNavigator: true,
      navigatorAutoFade: false,
      showNavigationControl: true,
      toolbar: "zoom-toolbar",
      navigatorPosition: 'BOTTOM_RIGHT',
      navigatorHeight: '180px',
      navigatorWidth: '500px',
      zoomInButton: "zoom-in",
      zoomOutButton: "zoom-out",
      homeButton: "home",
      fullPageButton: "full-page",
    })
  }

  async checkRouteParamForSiteID(data) {
    if (data) {
      return data
    } else {
      let id = this.route.snapshot.paramMap.get('id')
      return await this.extractTrueviewAssets(id)
    }
  }

  extractTrueviewAssets(id): Promise<Site> {
    return new Promise(resolve => {
      let site = Sites.find(site => site.id === id)
      resolve(site)
    })
  }

  async getTileSources(site): Promise<TileSources> {
    if (site.id === 'VP05') {
      return Promise.resolve([
        `/assets/viewpoints/${this.site.viewpoints.existing.id}/tiles.dzi`,
        `/assets/viewpoints/${this.site.viewpoints.fiftheenYears.id}/tiles.dzi`,
        `/assets/viewpoints/${this.site.viewpoints.twentyYears.id}/tiles.dzi`,
        `/assets/viewpoints/${this.site.viewpoints.thirtyYears.id}/tiles.dzi`,
      ])
    } else {
      return Promise.resolve([
        `/assets/viewpoints/${this.site.viewpoints.existing.id}/tiles.dzi`,
        `/assets/viewpoints/${this.site.viewpoints.proposed.id}/tiles.dzi`,
        `/assets/viewpoints/${this.site.viewpoints.overlay.id}/tiles.dzi`,
      ])
    }
  }

}
