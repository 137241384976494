import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgMaterialModule } from '@settings/ngmaterial.module';
import { AppBootstrapModule } from '@settings/ngx-bootstrap.module'

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    NgMaterialModule,
    AppBootstrapModule
  ],
  exports: [
    NgMaterialModule,
    AppBootstrapModule
  ]
})
export class SettingsModule { }
