<div id="openseadragon" [style.width.vw]="100" [style.height.vh]="100">
  <div id="zoom-toolbar">
    <button matTooltip="Reset" class="ml-5 mt-3" mat-mini-fab color="primary" id="home">
      <fa-icon [icon]="faUndoAlt" size="1x"></fa-icon>
    </button>
    <button matTooltip="Fullpage" class="ml-2 mt-3" mat-mini-fab id="full-page" color="primary">
      <fa-icon *ngIf="viewer" [icon]="viewer.isFullPage() ? faCompressArrowsAlt : faExpandArrowsAlt " size="1x"></fa-icon>
    </button>
    <button matTooltip="Zoom in" class="ml-2 mt-3" mat-mini-fab id="zoom-in" color="primary">
      <fa-icon [icon]="faSearchPlus" size="1x"></fa-icon>
    </button>
    <button matTooltip="Zoom out" class="ml-2 mt-3" mat-mini-fab color="primary" id="zoom-out">
      <fa-icon [icon]="faSearchMinus" size="1x"></fa-icon>
    </button>
    <button matTooltip="Go back to map page" mat-mini-fab class="float-right mr-5 mt-3" routerLinkActive="active" routerLink="/map">
      <fa-icon [icon]="faTimes" size="1x"></fa-icon>
    </button>
  </div>
  <div class="zoom-page">
      <app-zoom-toggles *ngIf="viewer" [site]="site" [viewer]="viewer"></app-zoom-toggles>
  </div>

</div>
