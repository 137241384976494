import { Injectable } from '@angular/core';
import { Subject } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class CalibrationDataService {

  private _calibrationDataSource = new Subject<string>()
  calibrationDataSource$ = this._calibrationDataSource.asObservable()

  constructor() { }

  sendCalibrationData(data:any) {
    this._calibrationDataSource.next(data)
  }
  
}
