import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SettingsModule } from '@settings/settings.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AppRoutingModule } from '@app/app-routing.module';
import { TrueviewsDialogComponent } from '@components/trueviews-dialog/trueviews-dialog.component';
import { TrueviewsVP05DialogComponent } from '@components/trueviews-vp05-dialog/trueviews-vp05-dialog.component'
import { CalibrationDialogComponent } from '@components/calibration-dialog/calibration-dialog.component';
import { Ng5SliderModule } from 'ng5-slider';
import { HeaderComponent } from '@components/header/header.component';
import { ComparisonModule } from '@components/image-comparison/image-comparison.module'

import { AngularSvgIconModule } from 'angular-svg-icon';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { ZoomTogglesComponent } from './zoom-toggles/zoom-toggles.component'

@NgModule({
  declarations: [
    TrueviewsDialogComponent,
    TrueviewsVP05DialogComponent,
    CalibrationDialogComponent,
    HeaderComponent,
    ZoomTogglesComponent
  ],
  imports: [
    CommonModule,
    SettingsModule,
    AppRoutingModule,
    FontAwesomeModule,
    Ng5SliderModule,
    ComparisonModule,
    AngularSvgIconModule.forRoot(),
    LeafletModule
  ],
  exports: [
    HeaderComponent,
    ZoomTogglesComponent,
    TrueviewsDialogComponent,
    TrueviewsVP05DialogComponent
  ],
  providers: [
    HeaderComponent,
    TrueviewsVP05DialogComponent,
    TrueviewsDialogComponent,
    ZoomTogglesComponent
  ],
  entryComponents: [
    TrueviewsDialogComponent
  ],
})
export class ComponentsModule {

  constructor() { }
}
