import { Injectable } from '@angular/core'
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot
} from '@angular/router'

import { MatDialog } from '@angular/material/dialog'
import { CalibrationDialogComponent } from '@components/calibration-dialog/calibration-dialog.component';

import { LocalStorageService } from 'ngx-localstorage';

@Injectable({
  providedIn: 'root'
})
export class ScreenCalibrationService implements CanActivate {

  constructor(public router: Router, private _storageService: LocalStorageService, public dialog: MatDialog) { }

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    const screen = await this._storageService.asPromisable().get('monitor')
    if (screen) {
      return true
    } else {
      this.dialog.open(CalibrationDialogComponent, {
        height: 'auto',
        width: '60vw',
      });
      //  this.router.navigate(['map']);
      return false;
    }
    return true;
  }

}
