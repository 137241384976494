import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ComparisonComponent } from '@components/image-comparison/image-comparison.component'
import { NgMaterialModule } from '@settings/ngmaterial.module';

@NgModule({
    declarations: [ComparisonComponent],
    exports: [ComparisonComponent],
    imports: [CommonModule, FontAwesomeModule, NgMaterialModule]
})
export class ComparisonModule { }
