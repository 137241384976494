import { Action } from '@ngrx/store';
import Site from '@models/site.model';

export enum SiteActionsTypes {
  ADD_SITE = '[SITE] Add Site',
  CLEAR_SITE = '[SITE] Clear Site',
}


export class AddSite implements Action {

  readonly type = SiteActionsTypes.ADD_SITE

  constructor(public payload: Site) { }

}

export class ClearSite implements Action {

  readonly type = SiteActionsTypes.CLEAR_SITE

}

export type Actions = AddSite | ClearSite