const Sites = [{
  "id": "VP01",
  "name": "Viewpoint 01",
  "title": "Near West - Charlotte Street, Queen Valley",
  "subtitle": "Looking East",
  "lat": 33.294936868,
  "lng": -111.2928106642,
  "orientation": 90,
  "icon": "../../assets/icons/cone.png",
  "data": [
    "Easting Position Arizona State Plane: 890620.9 ",
    "Northing Position Arizona State Plane: 835408.5",
    "Elevation of Viewpoint Position(NAD83): 2108.8",
    "Height of Camera Above Ground(ft): 2114.2",
    "Date of Photography: 15 October 2015 at 10:13 AM",
    "orientation of View: E",
    "Horizontal Field of View: 124°",
    "Vertical Field of View: 55°",
    "Distance to Tailings (miles): 4.2"
  ],
  "viewpoints": {
    "existing": {
      "id": "87234e089c",
      "text": "existing",
      "value": "existing"
    },
    "proposed": {
      "id": "a5059b1ad5",
      "text": "proposed",
      "value":"proposed"
    },
    "overlay": {
      "id": "f54e68e4a2",
      "text": "overlay",
      "value": "overlay"
    }
  }
}, {
  "id": "VP02",
  "name": "Viewpoint 02",
  "title": "Human Height - Arizona Trail, Montana Mountain",
  "subtitle": "Looking South",
  "lat": 33.4024915861,
  "lng": -111.1545406159,
  "orientation": 180,
  "icon": "../../assets/icons/cone.png",
  "data": [
    "Easting Position Arizona State Plane: 932584.4",
    "Northing Position Arizona State Plane: 874823.5",
    "Elevation of Viewpoint Position (NAD83): 5396.1",
    "Height of Camera Above Ground (ft): 5401.5",
    "Date of Photography: 14 October 2015 at 10:34 AM",
    "orientation of View: S",
    "Horizontal Field of View: 124°",
    "Vertical Field of View: 55°",
    "Distance to Tailings (miles): 4.7"
  ],
  "viewpoints": {
    "existing": {
      "id": "045594a460",
      "text": "existing",
      "value": 'existing'
    },
    "proposed": {
      "id": "0282cff31d",
      "text": "proposed",
      "value":"proposed"
    },
    "overlay": {
      "id": "1083494096",
      "text": "overlay",
      "value":"overlay"
    }
  }
}, {
  "id": "VP03",
  "name": "Viewpoint 03",
  "title": "US 60, Gonzales Pass, MP219",
  "subtitle": "Looking Northeast",
  "lat": 33.27655,
  "lng": -111.22763,
  "orientation": 45,
  "icon": "../../assets/icons/cone.png",
  "data": [
    "Easting Position Arizona State Plane: -138713.2",
    "Northing Position Arizona State Plane: 23384.2",
    "Elevation of Viewpoint Position (NAD83): 2549.4",
    "Height of Camera Above Ground (ft): 5.4",
    "Date of Photography: 8 March 2016 at 09:55 AM",
    "orientation of View: NE",
    "Horizontal Field of View: 124°",
    "Vertical Field of View: 55°",
    "Distance to Tailings (mi): 2.0"
  ],
  "viewpoints": {
    "existing": {
      "id": "a85453b51c",
      "text": "existing",
      "value": "existing"
    },
    "proposed": {
      "id": "33bafbec9d",
      "text": "proposed",
      "value":"proposed"
    },
    "overlay": {
      "id": "b6501c1855",
      "text": "overlay",
      "value":"overlay"
    }
  }
}, {
  "id": "VP04",
  "name": "Viewpoint 04",
  "title": "Picket Post House",
  "subtitle": "Looking Northwest",
  "lat": 33.2783,
  "lng": -111.15089,
  "orientation": 315,
  "icon": "../../assets/icons/cone.png",
  "data": [
    "Easting Position Arizona State Plane: -115264.3",
    "Northing Position Arizona State Plane: 24184.3",
    "Elevation of Viewpoint Position (NAD83): 2483.9",
    "Height of Camera Above Ground (ft): 5.4",
    "Date of Photography: 9 March 2016 at 11:27 AM",
    "orientation of View: NW",
    "Horizontal Field of View: 124°",
    "Vertical Field of View: 55°",
    "Distance to Tailings (mi): 2.5"
  ],
  "viewpoints": {
    "existing": {
      "id": "e04044574a",
      "text": "existing",
      "value": "existing"
    },
    "proposed": {
      "id": "d433d1469a",
      "text": "proposed",
      "value":"proposed"
    },
    "overlay": {
      "id": "661692f16c",
      "text": "overlay",
      "value":"overlay"
    }
  }
}, {
  "id": "VP05",
  "name": "Viewpoint 05",
  "title": "Dripping Springs Road",
  "subtitle": "Looking Northeast",
  "lat": 33.1723739722,
  "lng": -110.8674008833,
  "orientation": 315,
  "icon": "../../assets/icons/cone.png",
  "data": [
    "Longitude: 110° 52’ 2.6432” W",
    "Latitude: 33° 10’ 20.5463” N",
    "Elevation of Viewpoint Position (ft): 3226.1",
    "Height of Camera Above Ground (ft): 5.4",
    "Date of Photography: 14 August 2018 at 08:40 AM",
    "orientation of View: NW",
    "Horizontal Field of View: 130°",
    "Vertical Field of View: 46°",
  ],
  "viewpoints": {
    "existing": {
      "id": "1415e992af",
      "text": "existing",
      "value": "existing",
    },
    "fiftheenYears": {
      "id": "b8b796f6f0",
      "text": "15 Years",
      "value": "fiftheenYears",
      "before": "existing"
    },
    "twentyYears": {
      "id": "2b5f9b6c54",
      "text": "20 Years",
      "value": "twentyYears",
      "before": "fiftheenYears"
    },
    "thirtyYears": {
      "id": "abd8e9494e",
      "text": "30 Years",
      "value": "thirtyYears",
      "before": "twentyYears"
    }
  }
}]

export default Sites;