import { MapState } from '@models/map.state.model'
import * as MapActions from '@app/actions/map.actions'

const initialMapState: MapState = {
  center: [33.2783, -111.15089],
  zoom: 11,
  animate: true
}

export function MapReducer(state: MapState = initialMapState, action: MapActions.Actions) {
  switch (action.type) {
    case MapActions.ADD_MAP_STATE:
      return state = action.payload
    case MapActions.RESET_MAP_STATE:
      return initialMapState
    default:
      return state
  }

}
