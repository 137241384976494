import { Action } from '@ngrx/store';
import { MapState } from '@models/map.state.model';

export const ADD_MAP_STATE = '[MAP] Add'
export const RESET_MAP_STATE = '[MAP] Reset'

export class AddMap implements Action {

  readonly type = ADD_MAP_STATE 

  constructor(public payload: MapState) { }

}

export class ResetMap implements Action {

  readonly type = RESET_MAP_STATE

  constructor() { }

}

export type Actions = AddMap | ResetMap