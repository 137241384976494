  <mat-dialog-content class="image-comparison">
    <app-image-comparison [images]='images' [beforeLable]="'existing'" [afterLable]="'proposed'"></app-image-comparison>
  </mat-dialog-content>

  <section class="d-flex justify-content-between align-items-center mt-3">

    <div 
    style="height: 200px;width:200px"
    id="trueview-map"
    leaflet 
    [leafletOptions]="options">
          <div [leafletLayer]="layer"></div>
          <div [leafletLayer]="NearWestTailingsStorageFacility"></div>
    </div>
    
    <div mat-dialog-title class="trueview-dialog-header">
      <div class="trueview-comparision-title">
        <h2 class="text-uppercase font-weight-bold text-center">{{site.name}}</h2>
        <h3 class="text-capitalize font-weight-light text-center">{{site.title}}</h3>
        <h6 class="text-capitalize font-weight-light text-center">{{site.subtitle}}</h6>
      </div>
    </div>
    <div class="site-data">
      <div *ngFor="let data of site.data">
        {{data}}
      </div>
    </div>
    <mat-dialog-actions class="d-flex flex-column justify-content-between align-items-center">
      <div class="mb-5">
        <span color="warn">Proposed   </span>
        <mat-slide-toggle [color]="'warn'" [checked]="overlay" [disabled]="false" (toggleChange)="viewpointTypeToggled()">
          <span> Overlay</span>
        </mat-slide-toggle>
      </div>
      <div>
        <button mat-raised-button (click)="zoomTrueview(site)">
          <fa-icon [icon]="faSearchPlus" size="1x"></fa-icon> Zoom
        </button>
        <button mat-raised-button mat-dialog-close tabindex="-1" class=" mr-1 trueview-dismiss-btn">Close</button>
      </div>
    </mat-dialog-actions>
  </section>
