import { Component, OnInit, Inject, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { faSearchPlus, faMoon, faInfo } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router'

//models 
import Site, { Viewpoint } from '@models/site.model'

import LayersService from '@services/layers.service'
import { MAPTYPES } from '@models/map.types'
import { latLng, tileLayer, MapOptions, icon, Layer, marker } from 'leaflet'

import { Store } from '@ngrx/store';
import { SiteState } from '@models/site.state.model';
import { AddSite } from '@app/actions/site.actions';

@Component({
  selector: 'app-trueviews--vp05-dialog',
  templateUrl: './trueviews-vp05-dialog.component.html',
  styleUrls: ['./trueviews-vp05-dialog.component.scss']
})
export class TrueviewsVP05DialogComponent implements OnInit {

  faSearchPlus = faSearchPlus

  public before: Viewpoint = this.site.viewpoints.existing
  public after: Viewpoint = this.site.viewpoints.fiftheenYears

  public images: string[] = []

  public layer: Layer = marker(
    [this.site.lat, this.site.lng],
    {
      rotationAngle: this.site.orientation,
      rotationOrigin: 'center center',
      icon: icon({
        iconSize: [50, 50],
        iconUrl: '../../../assets/icons/cone.png',
      })
    })

    SkunkCampTailingsStorageFacility = this.layerService.layers[1]

    public options: MapOptions = {
      layers: [
        tileLayer(MAPTYPES.HYBRID, {
          subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
          id: 'trueview-map',
          maxZoom: 11,
          minZoom: 11
        })
      ],
      zoom: 13,
      zoomControl: false,
      center: latLng(this.site.lat, this.site.lng)
    }

  constructor(
    public dialogRef: MatDialogRef<TrueviewsVP05DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public site: Site,
    private router: Router,
    private store: Store<SiteState>,
    private layerService:LayersService) { }

  ngOnInit(): void {
    this.images = this.getComparisonImagesPath()
  }

  zoomTrueview(site: Site): void {
    this.store.dispatch(new AddSite(site))
    this.router.navigate(['map', site.id], { state: { data: site } })
    this.dialogRef.close();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  getImagePath(id: string): string {
    return `../../../assets/viewpoints/${id}/${id}.jpg`
  }

  getComparisonImagesPath(): string[] {
    return [
      this.getImagePath(this.after.id),
      this.getImagePath(this.before.id)
    ]
  }

  reclamationVisualsUpdated(value:string):void {
    let before = this.site.viewpoints[`${value}`].before
    this.before = this.site.viewpoints[`${before}`]
    this.after = this.site.viewpoints[`${value}`]
    this.dialogRef.componentInstance.images = this.getComparisonImagesPath()
  }

}

