import { NgModule } from '@angular/core';
import { Routes, RouterModule, CanActivate } from '@angular/router';

import { HomepageComponent } from '@homepage/homepage.component'
import { MapComponent } from '@map/map.component'
import { ZoomComponent } from '@zoom/zoom.component'
// import { NoPageFoundComponent } from './no-page-found/no-page-found.component'

import { ScreenCalibrationService } from '@services/screen-calibration.service'

const routes: Routes = [
  {
    path: '',
    component: HomepageComponent,
  },
  {
    path: 'map',
    component: MapComponent,
    // canActivate: [ScreenCalibrationService],
    data: { animation: 'map' }
  },
  {
    path: 'map/:id',
    component: ZoomComponent,
    canActivate: [ScreenCalibrationService]
  },
  {
    path: '**',
    component: HomepageComponent,
    data: { animation: 'home' }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
