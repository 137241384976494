import { Injectable } from '@angular/core';

import NearWestTailingsStorageFacility from '@assets/layers/near-west-tailings-storage-facility.geo.json'
import SkunkCampTailingsStorageFacility from '@assets/layers/skunk-camp-tailings-storage-facility.geo.json'

import * as L from "leaflet"
import { Layer } from 'leaflet';

@Injectable({
  providedIn: 'root'
})
export default class LayersService {

  private readonly NearWestTailingsStorageFacility: Layer = L.geoJSON(NearWestTailingsStorageFacility as any, {
    onEachFeature: (feature, layer) => {
      if (layer instanceof L.Polyline) {
        layer.setStyle({
          color: "#fff",
          fillOpacity: 1,
          weight: 3,
          fillColor: "#ffcc13",
          opacity: 1,
          fill: true
        })
      }
    }
  })

  private readonly SkunkCampTailingsStorageFacility: Layer = L.geoJSON(SkunkCampTailingsStorageFacility as any, {
    onEachFeature: (feature, layer) => {
      if (layer instanceof L.Polyline) {
        layer.setStyle({
          color: "#ffcc13",
          fillColor: "#ffcc13",
          fillOpacity: 1,
          opacity: 1,
          fill: true
        })
      }
    }
  })

  public readonly layers: Layer[] = [
    this.NearWestTailingsStorageFacility,
    this.SkunkCampTailingsStorageFacility
  ]

}
