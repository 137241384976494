<mat-button-toggle-group *ngIf="!viewpoint05" value="existing" class="before-after-toggle-button" #group="matButtonToggleGroup">
  <mat-button-toggle class="mat-button-toggle-zoom" value="{{site.viewpoints.overlay.value}}" (click)="viewer.goToPage(2);showSnakbar(site.viewpoints.overlay.text)">
    {{site.viewpoints.overlay.text | titlecase }}
  </mat-button-toggle>
  <mat-button-toggle class="mat-button-toggle-zoom" value="{{site.viewpoints.proposed.value}}" (click)="viewer.goToPage(1);showSnakbar(site.viewpoints.proposed.text)">
    {{site.viewpoints.proposed.text | titlecase }}
  </mat-button-toggle>
  <mat-button-toggle class="mat-button-toggle-zoom" value="{{site.viewpoints.existing.value}}" (click)="viewer.goToPage(0);showSnakbar(site.viewpoints.existing.text)">
    {{site.viewpoints.existing.text | titlecase }}
  </mat-button-toggle>
</mat-button-toggle-group>


<mat-button-toggle-group *ngIf="viewpoint05" value="existing" class="before-after-toggle-button" #group="matButtonToggleGroup">

  <mat-button-toggle class="mat-button-toggle-zoom" value="{{site.viewpoints.thirtyYears.value}}" (click)="viewer.goToPage(3);showSnakbar(site.viewpoints.thirtyYears.text)">
    {{site.viewpoints.thirtyYears.text | titlecase }}
  </mat-button-toggle>

  <mat-button-toggle class="mat-button-toggle-zoom" value="{{site.viewpoints.twentyYears.value}}" (click)="viewer.goToPage(2);showSnakbar(site.viewpoints.twentyYears.text)">
    {{site.viewpoints.twentyYears.text | titlecase }}
  </mat-button-toggle>

  <mat-button-toggle class="mat-button-toggle-zoom" value="{{site.viewpoints.fiftheenYears.value}}" (click)="viewer.goToPage(1);showSnakbar(site.viewpoints.fiftheenYears.text)">
    {{site.viewpoints.fiftheenYears.text | titlecase }}
  </mat-button-toggle>

  <mat-button-toggle class="mat-button-toggle-zoom" value="{{site.viewpoints.existing.value}}" (click)="viewer.goToPage(0);showSnakbar(site.viewpoints.existing.text)">
    {{site.viewpoints.existing.text | titlecase }}
  </mat-button-toggle>

</mat-button-toggle-group>
