import { BrowserModule } from '@angular/platform-browser'
import { NgModule } from '@angular/core'

import { AppRoutingModule } from '@app/app-routing.module'
import { AppComponent } from '@app/app.component'

//  Angular wrapper for mapbox-gl-js.
import { HomepageComponent } from '@homepage/homepage.component'
import { MapComponent } from '@map/map.component'

import { SettingsModule } from './settings/settings.module'
import { ComponentsModule } from '@components/components.module'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'

// provides a means to inline SVG files to be easily styled by CSS and code.
import { AngularSvgIconModule } from 'angular-svg-icon'
import { HttpClientModule } from '@angular/common/http'
import { ZoomComponent } from '@zoom/zoom.component'
import { NgxLocalStorageModule } from 'ngx-localstorage'
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { ServiceWorkerModule } from '@angular/service-worker'
import { environment } from '../environments/environment'
import { StoreModule } from '@ngrx/store';
import { SiteReducer } from '@app/reducers/site.reducer'
import { MapReducer } from '@app/reducers/map.reducer'

import { LeafletModule } from '@asymmetrik/ngx-leaflet'

import 'leaflet-rotatedmarker'

@NgModule({
  declarations: [
    AppComponent,
    HomepageComponent,
    MapComponent,
    ZoomComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SettingsModule,
    ComponentsModule,
    FontAwesomeModule,
    HttpClientModule,
    AngularSvgIconModule.forRoot(),
    BrowserAnimationsModule,
    NgxLocalStorageModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    StoreModule.forRoot({
      site: SiteReducer,
      map: MapReducer,
    }),
    LeafletModule
  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
