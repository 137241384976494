import { Component, OnInit, Inject, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { faSearchPlus, faMoon, faInfo } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router'

//models 
import Site, { Viewpoint } from '@models/site.model'

import LayersService from '@services/layers.service'
import { MAPTYPES } from '@models/map.types'
import { latLng, tileLayer, MapOptions, icon, Layer, marker } from 'leaflet'

import { Store } from '@ngrx/store';
import { SiteState } from '@models/site.state.model';
import { AddSite } from '@app/actions/site.actions';

@Component({
  selector: 'app-trueviews-dialog',
  templateUrl: './trueviews-dialog.component.html',
  styleUrls: ['./trueviews-dialog.component.scss']
})
export class TrueviewsDialogComponent implements OnInit {

  faSearchPlus = faSearchPlus
  faMoon = faMoon
  faInfo = faInfo

  public overlay: boolean = false
  private proposed: Viewpoint = this.site.viewpoints.proposed
  public images: string[] = []

  public layer: Layer = marker(
    [this.site.lat, this.site.lng],
    {
      rotationAngle: this.site.orientation,
      rotationOrigin: 'center center',
      icon: icon({
        iconSize: [50, 50],
        iconUrl: '../../../assets/icons/cone.png',
      })
    })

    NearWestTailingsStorageFacility = this.layerService.layers[0]

    public options: MapOptions = {
      layers: [
        tileLayer(MAPTYPES.HYBRID, {
          subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
          id: 'trueview-map',
          maxZoom: 10,
          minZoom: 10
        })
      ],
      zoom: 13,
      zoomControl: false,
      center: latLng(this.site.lat, this.site.lng)
    }

  constructor(
    public dialogRef: MatDialogRef<TrueviewsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public site: Site,
    private router: Router,
    private store: Store<SiteState>,
    private layerService:LayersService) { }

  ngOnInit(): void {
    this.images = this.getComparisonImagesPath()
  }

  zoomTrueview(site: Site): void {
    this.store.dispatch(new AddSite(site))
    this.router.navigate(['map', site.id], { state: { data: site } })
    this.dialogRef.close();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  getImagePath(id: string): string {
    return `../../../assets/viewpoints/${id}/${id}.jpg`
  }

  getComparisonImagesPath(): string[] {
    return [
      this.getImagePath(this.proposed.id),
      this.getImagePath(this.site.viewpoints.existing.id)
    ]
  }

  public viewpointTypeToggled() {
    this.proposed = this.overlay ? this.site.viewpoints.proposed : this.site.viewpoints.overlay
    this.overlay = !this.overlay
    this.dialogRef.componentInstance.images = this.getComparisonImagesPath()
  }

}

