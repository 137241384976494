import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { TrueviewsDialogComponent } from '@components/trueviews-dialog/trueviews-dialog.component';
import { TrueviewsVP05DialogComponent } from '@components/trueviews-vp05-dialog/trueviews-vp05-dialog.component';

import { of, Observable } from 'rxjs';
import Sites from '@documents/sites.document'
import Site from "@models/site.model"

// private sites: Observable<Site[]> = of(Sites)

@Injectable({
  providedIn: 'root'
})
export class SitesService {

  constructor(
    private dialog: MatDialog) {
  }

  private sitesAsObservables(): Observable<Site[]> {
    return of(Sites)
  }

  public getSites(): Observable<Site[]> {
    const sites = this.sitesAsObservables()
    return sites
  }

  public openSiteTrueviewsDialog(site: Site): void {
    switch (site.id) {
      case 'VP05':
        this.dialog.open(TrueviewsVP05DialogComponent, {
          data: site,
          id: 'trueview-comparison-dialog',
          height: 'auto',
          width: '95vw',
        });
        break
      default:
        this.dialog.open(TrueviewsDialogComponent, {
          data: site,
          id: 'trueview-comparison-dialog',
          height: 'auto',
          width: '95vw',
        });
    }
  }
}

