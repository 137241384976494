import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { faHome, faTv, faExclamationTriangle, faUndo } from '@fortawesome/free-solid-svg-icons'
import { MatDialog } from '@angular/material/dialog'
import { CalibrationDialogComponent } from '@components/calibration-dialog/calibration-dialog.component';

import { LocalStorageService } from 'ngx-localstorage';
import { CalibrationDataService } from '@services/calibration-data.service'

import { Store } from '@ngrx/store';
import { SiteState } from '@models/site.state.model';
import { ClearSite } from '@app/actions/site.actions';


@Component({
  selector: 'map-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  public readonly faHome = faHome
  public readonly faTv = faTv
  public readonly faExclamationTriangle = faExclamationTriangle
  public readonly faUndo = faUndo

  public isScreenCalibrated: boolean = false

  @Output() resetView = new EventEmitter()

  constructor(
    public dialog: MatDialog,
    private _storageService: LocalStorageService,
    private calibrationDataService: CalibrationDataService,
    private store: Store<SiteState>) { }

  async ngOnInit() {
    this.isScreenCalibrated = await this.CheckLocalStorageForScreenCalibration()
    this.calibrationDataService.calibrationDataSource$.subscribe(result => {
      if (result) {
        this.isScreenCalibrated = true
      }
    })
  }

  calibrateScreen() {
    this.store.dispatch(new ClearSite())
    const dialogRef = this.dialog.open(CalibrationDialogComponent, {
      id:'calibration-dialog',
      height: 'auto',
      width: '60vw',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.CheckLocalStorageForScreenCalibration().then(screen => {
          if (screen) {
            this.isScreenCalibrated = true
          }
        })
      }
    })
  }

  async CheckLocalStorageForScreenCalibration() {
    const localStorage = await this._storageService.asPromisable().get('monitor')
    return localStorage === null ? false : true
  }

}
