import { Component, OnInit } from '@angular/core';
import { faInfoCircle, faDesktop } from '@fortawesome/free-solid-svg-icons'
import { MatDialogRef } from '@angular/material/dialog';

import { Options } from 'ng5-slider';
import { LocalStorageService } from 'ngx-localstorage';
import { CalibrationDataService } from '@services/calibration-data.service'

import { MatSnackBar } from '@angular/material/snack-bar';
import { SiteState } from '@models/site.state.model';

import { Store } from '@ngrx/store';
import Site from '@models/site.model';
import { Router } from '@angular/router'
import { SiteActionsTypes } from '@app/actions/site.actions';


@Component({
  selector: 'app-calibration-dialog',
  templateUrl: './calibration-dialog.component.html',
  styleUrls: ['./calibration-dialog.component.scss']
})
export class CalibrationDialogComponent implements OnInit {

  public faInfoCircle = faInfoCircle
  public faDesktop = faDesktop

  public ccSize: number = 175;
  public ccRatio: number = 1.585772508336421;
  public ccBase: number = 100;
  public screenSize: number = 0;

  public ccWidth: number
  public ccHeight: number

  public screen: any = {
    width: window.screen.width,
    height: window.screen.height
  };

  public rPixel

  site: Site

  public options: Options = {
    floor: -30,
    ceil: 320,
    step: 1,
    hideLimitLabels: true,
    showTicks: true,
    translate: () => `${this.screenSize.toFixed(2).toString()}"`,
    getPointerColor: () => '#f66'
  };

  constructor(
    public dialogRef: MatDialogRef<CalibrationDialogComponent>,
    private _storageService: LocalStorageService,
    private calibrationDataService: CalibrationDataService,
    private _snackBar: MatSnackBar,
    private store: Store<any>,
    private router: Router) {

  }

  ngOnInit(): void {
    this.updateSize()
    this.store.select("site").pipe().subscribe((site: Site) => this.site = site)
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  updateSize() {
    this.screen = {
      width: window.screen.width,
      height: window.screen.height
    };
    this.ccWidth = (this.ccBase * this.ccRatio) + (this.ccSize * this.ccRatio);
    this.ccHeight = this.ccBase + this.ccSize;
    let pixelHypotenuse = Math.sqrt(Math.pow(this.screen.width, 2) + Math.pow(this.screen.height, 2));
    this.rPixel = this.ccWidth / 85.6;
    let screenHypotenuse = pixelHypotenuse / this.rPixel;
    this.screenSize = screenHypotenuse / 25.4;
    // Well leave the actual width and height in mm.
    this.screen.hypotenuse = pixelHypotenuse;
    this.screen.rPixel = this.rPixel;
    this.screen.actualHypotenuse = screenHypotenuse;
    this.screen.actualWidth = this.screen.width / this.rPixel;
    this.screen.actualHeight = this.screen.height / this.rPixel;
  }

  calibrationFinish() {
    this._storageService.asPromisable().set('monitor', this.screen).then(result => {
      if (result) {
        this.calibrationDataService.sendCalibrationData(result)
        if (this.site) {
          this.router.navigate(['map', this.site.id], { state: { data: this.site } })
        } else {
        this._snackBar.open('Screen is calibrated successfully.', 'dismiss', {
          duration: 2000,
        });
        }
        this.dialogRef.close(result)
      }
    })
  }

}
