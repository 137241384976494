<div class="text-center">
  <h3 class="h2">Screen Calibration Wizard</h3>
  <h5 class="h4">Welcome to the Resolution Copper Project Explorer.</h5>
</div>
<mat-horizontal-stepper #stepper [linear]="false">
  <mat-step class="clearfix">
    <ng-template matStepLabel>Start Screen Calibration</ng-template>
    <p class="mt-2 lead">This wizard will guide you through calibrating your screen for the correct viewing experience. During calibration, we will ask you to hold a store or loyalty card against the monitor.</p>
    <fa-icon [icon]="faInfoCircle" size="1x" class="text-danger"></fa-icon> <small class="text-muted"> We do not capture, store or photograph any of your card details , but use the size of the card to calibrate your screen, as it is a standard size across the world.</small>
    <button mat-button matStepperNext class="mt-3 float-right">Next</button>
  </mat-step>
  <mat-step>
    <ng-template matStepLabel>Screen Size </ng-template>
    <div class="credit-card outlined" [style.width.px]="ccWidth" [style.height.px]="ccHeight">
      <div class="dot"></div>
    </div>
    <ng5-slider [(value)]="ccSize" [options]="options" (userChange)="updateSize()"></ng5-slider>
    <div class="float-right">
      <button mat-button matStepperPrevious>Back</button>
      <button mat-button matStepperNext>Next</button>
    </div>
  </mat-step>
  <mat-step>
    <ng-template matStepLabel>Screen Calibration Complete</ng-template>
    <div class="row">
      <div class="column col-6">
        <fa-layers [fixedWidth]="true" class="position-static">
          <fa-icon [icon]="faDesktop" size="10x"></fa-icon>
          <fa-layers-text content='{{screenSize | number }}"'  [style.color]="'#0033a1'" [style.fontSize.px]="'36'"></fa-layers-text>
        </fa-layers>
      </div>
      <div class="column col-6">
        <p class="lead mt-2">Your monitor appears to be around {{screenSize | number }}" . If that does not seem right, press the back button to try calibration again. Otherwise, click finish to continue.</p>
        <p class="lead">When using Zoom mode please view at a distance of 19.7" (approximately arm's length) from the screen.</p>
      </div>
    </div>

    <div class="float-right calibration-btns">
      <button mat-button matStepperPrevious>Back</button>
      <button mat-button (click)="stepper.reset()">Reset</button>
      <button mat-raised-button (click)="calibrationFinish()">Done</button>
    </div>
  </mat-step>
</mat-horizontal-stepper>
